<template>
	<div class="dashboard">
		<AppTopbar
		title="Serviceability"
		buttonText="New Order"
		buttonLink="/new"
		buttonIconClass="icon-add"
		></AppTopbar>

		<div class="container-fluid pt-70 pt-md-100">
			<div class="card">
				<div class="card__title">
					<span class="fs18 fw500  d-block">
						Order Information
					</span>
				</div>
				<div class="card__content">
					<b-form @submit.prevent="search" class="row">
						<div class="col-md-3">
							<b-form-group
							id="select-warehouse"
							label="Select Warehouse"
							label-for="warehouse"
							required
							class="mb-10"
							>
							<multiselect
							:searchable="true"
							:show-labels="false"
							placeholder="Select Address"
							id="warehouse"
							label="name"
							:allowEmpty="false"
							:options="warehouses"
							v-model="warehouse"
							></multiselect>
							<small v-if="error" class="text-red">{{error}}</small>
						</b-form-group>
					</div>
					<div class="col-md-3">
						<b-form-group
						id="destination-pincode"
						label="Destination Pin Code"
						label-for="pin-code"
						class="mb-10"
						>
						<b-form-input
						id="pin-code"
						maxlength="6"
						minlength="6"
						required
						v-model="pin"
						@keyup="numberValidation($event.target.value)"
		                placeholder="Source Pin Code"
		                oninvalid="this.setCustomValidity('Enter valid pincode.')"
                      	oninput="this.setCustomValidity('')"
		                :formatter="numbers"
		                type="text"
		                :state="pincode" 
						></b-form-input>
					</b-form-group>
				</div>
				<div class="col-md-3">
					<b-form-group class="mb-10">
						<b-button type="submit" :disabled="!pincode" variant="primary px-20 mt-md-28">
							Search <b-spinner v-if="submitting" class="ml-10"></b-spinner>
						</b-button> 
					</b-form-group>
				</div>
			</b-form>
		</div>
	</div>
</div>

<div class="container-fluid pt-32 pb-24" v-if="showResults">
	<div class="card">
		<div class="card__title">
			<span class="fs16 lh24 fw500">
				Serviceability Statistics
			</span>
		</div>
		<div class="card__content">
			<PincodeTable :items="items" />
		</div>
	</div>
</div>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PincodeTable from "@/components/PincodeTable.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";

export default {
	name: "PincodeChecks",
	title: "Shyplite - Pin Code Check",
	data() {
		return {
			showResults : false,
			submitting 	: false,
			warehouse 	: null,
			pin 		: null,
			warehouses 	: this.$store.getters.warehouses,
			items 		: [],
			error 		: '',
			pincode 	: null
		};
	},
	components: {
		AppTopbar,
		Multiselect,
		PincodeTable,
	},
	methods: {
		numberValidation(val) {
            if (val || val == '') {
                if(val.match(/^[0-9]{6}$/)){
                this.pincode = true
              } else if (val == '') {
                this.pincode = null
              } else{
                this.pincode = false
              }
            } 
        },
        maxsix(e){
            return String(e).substring(0, 6);
        },
		async search() {
			try {
				this.submitting = true
				if(!this.warehouse) {
					this.error = 'Warehouse is required.'
					this.submitting = false
					return
				}
				this.error = ''
				let res = await this.axios.post('/settings/serviceability', {addressID:String(this.warehouse.id), pin:this.pin})
				res = res.data.serviceability
				this.items = [
				{
					modeService	: "Air",
					prepaid 	: res.airPrepaid.length>0? true : false,
					cod 		: res.airCod.length>0? true : false,
					reverse 	: res.airPrepaid.length>0? true : false,
				},
				{
					modeService : "Surface - 30kg",
					prepaid 	: res.surface30kgPrepaid.length>0 ? true : false,
					cod 		: res.surface30kgCod.length>0 ? true : false,
					reverse 	: res.surface30kgReverse.length>0 ? true : false,
				},
				{
					modeService : "Surface - 10kg",
					prepaid 	: res.surface10kgPrepaid.length>0 ? true : false,
					cod 		: res.surface10kgCod.length>0 ? true : false,
					reverse 	: res.surface10kgReverse.length>0 ? true : false,
				},
				{
					modeService : "Surface - 5kg",
					prepaid 	: res.surface5kgPrepaid.length>0 ? true : false,
					cod 		: res.surface5kgCod.length>0 ? true : false,
					reverse 	: res.surface5kgReverse.length>0 ? true : false,
				},
				{
					modeService : "Lite - 0.5kg",
					prepaid 	: res['lite0.5kgPrepaid'].length>0 ? true : false,
					cod 		: res['lite0.5kgCod'].length>0 ? true : false,
					reverse 	: res['lite0.5kgReverse'].length>0 ? true : false,
				},
				{
					modeService : "Lite - 1kg",
					prepaid 	: res.lite1kgPrepaid.length>0 ? true : false,
					cod 		: res.lite1kgCod.length>0 ? true : false,
					reverse 	: res.lite1kgReverse.length>0 ? true : false,
				},
				{
					modeService : "Lite - 2kg",
					prepaid 	: res.lite2kgPrepaid.length>0 ? true : false,
					cod 		: res.lite2kgCod.length>0 ? true : false,
					reverse 	: res.lite2kgReverse.length>0 ? true : false,
				}
				],
				this.showResults = true
				this.submitting = false
			} catch(e) {
				console.log(e);
				this.submitting = false
			}
		},
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped>
.card .card__content {
	overflow: initial;
}
</style>