    <template>
    	<div class="webhooks" id="log-detail">
    		<AppTopbar
    		title="Webhook Detail"
    		buttonText="New Order"
    		buttonIconClass="icon-add"
    		buttonLink="/new"
    		/>

    		<div class="container-fluid pt-70 pt-md-98">
    			<div class="card">
    				<div class="card__title title"  >
                        <div class="row col-md-12">
                            <span class="fs28 lh24 fw500">
                                Logs
                            </span> 
                        </div>
                        <div class="row fs16 pt-20">
                            <div class="col-md-2 statusBar" :class="{ active: logStatus  }" @click="changeStatustrue">Succeeded</div>
                            <div class="col-md-2 statusBar" :class="{ active: !logStatus  }" @click="changeStatusfalse">Failed</div>
                        </div>
    				</div>
    				<div class="card__content overflow-visible">
                            <div class="row pt-20 col-md-8">
                                <div class="col-md-4">
                                    <date-picker
                                    prefix-class="xmx"
                                    v-model="form.date"
                                    id="dp"
                                    range
                                    value-type="format"
                                    class="mb-17"
                                    format="YYYY-MM-DD"
                                    placeholder="Start Date - End Date"
                                    :editable="false"
                                    @change="changeDate"
                                    :disabled-date="(date) => date > new Date()"
                                  ></date-picker>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group id="mode-field" label="" label-for="events">
                                      <multiselect
                                        id="events"
                                        :options="versions"
                                        placeholder="Select Version"
                                        track-by="name"
                                        :searchable="false"
                                        label="name"
                                        :disabled="disableVersion"
                                        :allow-empty="true"
                                        :show-labels="false"
                                        v-model="form.version"
                                        @input="changeVersion"
                                      >
                                      </multiselect>
                                    </b-form-group>
                                </div>
                            </div> 
                            <div class="row col-md-12 pt-20 pt-md-20" :class="{'justify-content-center' : !listData.length}">
                                <div class="col-md-6" v-if="listData.length">
                                    <ul class="events" v-for="(item, index) in finalObj[0]" :key="index"> 
                                        <div class="pb-10 date">{{index}}</div>
                                        <div :class="{'mt-10' : index == 0, 'activeItem': j.timestamp == activeIndex}" class="listItem justify-content-between row col-md-12 ml-0" v-for="(j,index) in item" :key="j.timestamp" @click="showResponse(j.timestamp,index)">
                                                <div class="col-md-8 row">
                                                    <div class="col-md-2 statusCode"> {{j.statusCode}}  </div>
                                                    <div class="col-md-5 my-3"> {{j.event}} </div>
                                                </div>
                                                <div class="col-md-4 text-right my-auto">
                                                    <span> {{j.timestamp | hourtime}} </span>
                                                </div>
                                        </div>
                                    </ul>
                                    <div class="col p-0">
                                        <div class="pagination-bar">
                                          <div class="container-fluid pagination-fluid">
                                            <div class="row">
                                              <div class="col-4">
                                                <div class="d-inline-flex wsnw align-items-center">
                                                  <div class="d-block w50px">
                                                    <multiselect
                                                      class="pagination-items__pp"
                                                      v-model="perPage"
                                                      :searchable="false"
                                                      :show-labels="false"
                                                      :allow-empty="false"
                                                      :options="pageOptions"
                                                      @input="setPageNumber"
                                                    ></multiselect>
                                                  </div>
                                                  <span
                                                    class="pl-8 fs12 lh24 text-gray-600 pagination-items"
                                                  ></span>
                                                </div>
                                              </div>
                                              <div class="col-8 text-right">
                                                <div class="d-inline-flex ml-auto wsnw align-items-center">
                                                  <p class="fs12 lh24 text-gray-600 pr-14">
                                                    Page: {{ currentPage }}
                                                  </p>

                                                  <b-button-group>
                                                    <b-button
                                                      @click="prevPage"
                                                      :disabled="prevDisabled"
                                                      variant="paginate left"
                                                    >
                                                      <i class="icon-back fs12"></i>
                                                    </b-button>

                                                    <b-button
                                                      @click="nextPage"
                                                      :disabled="nextDisabled"
                                                      variant="paginate right"
                                                    >
                                                      <i class="icon-arrow fs12"></i>
                                                    </b-button>
                                                  </b-button-group>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row mt-10">
                                  <div class="">
                                    <div class="d-flex flex-column align-items-center">
                                      <i class="icon-empty-state text-blue pb-24 fs30"></i>
                                      <span class="fs14 fw500 pb-8 text-blue">
                                        Couldn't find any Logs for this Webhook or with the Version/Status type you have Selected
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="respData.length" class="col-md-6">
                                    <div v-for="item in respData" :key="item[0]">
                                        <div class="log-detail" >
                                            <div class="col">
                                            <div class="card">
                                                <div class="card__title resp__card title">
                                                    <div class="row col-md-12">
                                                        <span class="fs25 lh24 fw500">
                                                            Log Details
                                                        </span> 
                                                    </div>
                                                </div>
                                                <div class="card__content detailsCard" >
                                                    <p id="detailsPara"></p>
                                                    <div class="row col-md-12">
                                                        <div class="col-md-4">
                                                            <div class="col"><strong>Status :</strong></div>
                                                            <div class="col"><strong>Time :</strong></div>
                                                            <div class="col"><strong>API Version :</strong></div>
                                                            <div class="col" v-if="item[1].importedID"><strong>Order ID :</strong></div>
                                                            <div class="col"><strong>IP Address :</strong></div>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <div class="col">{{item[1].statusCode}}</div>
                                                            <div class="col">{{item[1].timestamp | logdate}}, {{item[1].timestamp | hourtime}}</div>
                                                            <div class="col">{{item[1].apiVersion}}</div>
                                                            <div class="col" v-if="item[1].importedID">{{item[1].importedID}}</div>
                                                            <div class="col">{{item[1].ip}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col col mx-30">
                                            <label><strong>Response Body</strong></label>
                                            <pre v-highlightjs><code class="javascript">{{item[1].body}}</code></pre>

                                            <!-- <pre v-highlightjs="item[1].body"><code class="javascript"></code></pre> -->
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                                
                            </div>         
    				</div>
    			</div>

    		</div>

            <WebhookLogDetails :respData="respData" ref="WebhookLogDetails" />
    	</div>
    </template>
    <script>
    import Multiselect from "vue-multiselect";
    import AppTopbar from "@/components/layout/AppTopbar.vue";
    import WebhookLogDetails from "@/components/WebhookLogDetails.vue";
    import DatePicker from "vue2-datepicker";
    import VueHighlightJS from 'vue-highlightjs'
    import Vue from 'vue'
    Vue.use(VueHighlightJS)

    export default {
    	name: "Webhook_Logs",
    	title: "Shyplite - Webhook Logs",
    	data() {
    		return {
    			revealFlag:false,
                webhook_id: '',
                webhookDetail:{},
                pageOptions: this.$store.state.pageOptions,
                form:{
                    date:null
                },
                listData:[],
                prevDisabled: true,
                nextDisabled: false,
                perPage: this.$store.state.perPage,
                currentPage: 1,
                respData:[],
                pagesArr:[],
                totalPages: 1,
                logStatus:true,
                activeIndex:null,
                finalObj:[],
                options: [
                    { name: "ORDER", $isDisabled: true },
                    { name: "order.created", id: "1" },
                    { name: "order.updated", id: "2" },
                    { name: "order.cancelled", id: "3" },
                    { name: "order.booked", id: "5" },
                    { name: "TRACKING", $isDisabled: true },
                    { name: "tracking.pickedup", id: "6" },
                    { name: "tracking.retrurnOrReverse", id: "7" },
                    { name: "tracking.outfordelivery", id: "8" },
                    { name: "tracking.delivered", id: "9" }
                    // { name: "BILLING", $isDisabled: true },
                    // { name: "billing.estimatedamount", id: "10" },
                    // { name: "billing.estimatedamount", id: "11" },
                    // { name: "PAYMENT", $isDisabled: true },
                    // { name: "payment.completed", id: "12" },
                    // { name: "payment.error", id: "13" },
                    // { name: "payment.pending", id: "14" },
                    // { name: "payment.failed", id: "15" },
                    // { name: "SERVICEABILITY", $isDisabled: true },
                    // { name: "serviceability.updated", id: "16" },
                  ],
                  versions: [
                    { name: "Select Events", $isDisabled: true }
                  ],
                  webhook_id: '',
                  first:true
    		};
    	},
    	components: {
    		Multiselect,
    		AppTopbar,
            WebhookLogDetails,
            DatePicker    	
        },
    	computed:{
    		windowWidth() {
    			return this.$store.state.windowWidth;
    		},
            disableVersion() {
                return this.versions.length == 0;
            }
    	},
        created() {
            var today = new Date()
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            const todayDate = yyyy + '-' + mm + '-' + dd;

            var last = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
            var dd = String(last.getDate()).padStart(2, '0');
            var mm = String(last.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = last.getFullYear();

            last = yyyy + '-' + mm + '-' + dd;

            this.form.date = [last,todayDate]

            this.totalRows = this.listData.length;
            this.totalPages = Math.ceil(this.totalRows / this.perPage);
            for (var i = 1; i <= this.totalPages; i++) {
              this.pagesArr.push(i);
            }

            this.webhook_id = this.$route.params.id
            this.getData()
        },
    	methods: {
            nextPage() {
              this.currentPage++;
              this.getData();
            },
            prevPage() {
              this.currentPage--;
              this.getData();
            },
            setPageNumber() {
              this.currentPage = 1;
              this.getData();
              this.totalPages = Math.ceil(this.totalRows / this.perPage);
              this.pagesArr = [];
              for (var i = 1; i <= this.totalPages; i++) {
                this.pagesArr.push(i);
              }
              this.currentPage = 1;
              this.prevDisabled = true;
              if (this.totalPages == 1) {
                this.nextDisabled = true;
              } else if (this.totalPages > 1) {
                this.nextDisabled = false;
              }
            },
            changeVersion() {
                let version = this.form.version ? this.form.version.name :''
                this.getData(version)
            },
            changeDate() {
                this.getData()
            },
            changeStatustrue() {
                this.logStatus = true
                this.respData = []
                this.getData()
            },
            changeStatusfalse() {
                this.logStatus = false
                this.respData = []
                this.getData()
            },
            showResponse(ts,index) {
                
                this.activeIndex = ts
                this.respData = []

                this.respData = this.listData.filter(i => i[1].timestamp == ts)
                let respdata = this.respData[0][1].body

                if (respdata.length > 2) {
                    let def = JSON.parse(JSON.parse(respdata))
                    this.respData[0][1].body = JSON.stringify(def, undefined, 4)
                } 

                if (this.windowWidth > 767) {

                    let elmnt = document.getElementById('log-detail');
                    elmnt.scrollIntoView();
                }
                else {
                    this.WebhookLog();
                }

            },
            WebhookLog() {
                this.$refs.WebhookLogDetails.isOpen = !this.$refs.WebhookLogDetails.isOpen;
            },
            async getData(vers) {
                let localfrom = new Date(this.form.date[0])
                let localto = new Date(this.form.date[1])

                localfrom = +(new Date(localfrom.getFullYear(), localfrom.getMonth(), localfrom.getDate(), 0, 0, 0))
                localto = +(new Date(localto.getFullYear(), localto.getMonth(), localto.getDate(), 23, 59, 59))
                if (localto > +new Date()) {
                    localto = +new Date()
                } 
                const data = {
                    from : localfrom,
                    to : localto,
                    pageNum : this.currentPage,
                    pageSize : this.perPage,
                    subscriptionID : this.webhook_id,
                    apiVersion : vers ? vers : '',
                    status : this.logStatus
                }
                try {
                    const res = await this.axios.get(`/v3/wh/log?from=${data.from}&to=${data.to}&pageNum=${data.pageNum}&pageSize=${data.pageSize}&subscriptionID=${data.subscriptionID}&apiVersion=${data.apiVersion}&status=${data.status}`,data)
                    if (res.data.success) {
                        this.listData = res.data.data.logs
                        let requiredData = this.listData.map(i => i[1].apiVersion);
                        requiredData = [...new Set(requiredData)];
                        this.versions = requiredData.map(i => {
                            return {name: i};
                        });
                        let arr = [...this.listData]
                        arr = arr.map(i => {
                            const date = new Date(i[0]);
                            const month = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"][date.getMonth()];
                            return i = {
                                0 : `${month} ${date.getDate()}, ${date.getFullYear()}`,
                                1 : i[1]
                            }
                        })
                        const obj = {}
                        arr = arr.forEach(i => {
                            if (obj[i[0]]) {
                                obj[i[0]].push(i[1]);
                            }
                            else {
                                obj[i[0]] = [i[1]];
                            }
                        });
                        this.finalObj = [...[obj]]
                        this.nextDisabled = !res.data.data.nextPage
                    } else {
                        // this.popToast("failed", "Failed!", "Data Fetching Failed!! Please Try again Later");
                    }
                } catch(e) {
                    this.popToast("failed", "Failed!", "Data Fetching Failed!! Please Try again Later");                }
            }
    	},
        watch: {
            currentPage: function() {
              this.prevDisabled = false;
              this.nextDisabled = false;

              if (this.currentPage == 1) {
                this.prevDisabled = true;
              }

              if (this.currentPage == this.totalPages) {
                this.nextDisabled = true;
              }
            }
          }
    };
    </script>

    <style scoped>
    .log-detail{
        overflow: auto;
    }
    .pagination-fluid {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .pagination-bar {
        background-color: #EFF2F5;
        padding: 0.75rem 0 0.75rem 0;
        bottom: 0;
        position: unset;
        width: unset;
        justify-content: space-between;
        }
    .detailsCard {
        padding: 1.5rem 2rem 1.875rem 1rem;
    }
    .statusCode {
        padding: 0.2rem 0.1rem 0.1rem 1rem;
        height: 1.5rem;
        background: #cfcfd0;
        width: 3.5rem;
        border-radius: 5px !important;
    }
    .date {
        border-bottom: 1px solid #a9abad;
    }
    .events {
        padding: unset;
    }
    .statusBar {
        max-width: 10rem;
        cursor: pointer;   
    }
    .active {
        color: #006eff;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #006eff;
    }
    /deep/ a:hover, a:focus, a:active {
    	color: #006eff;
    }
    .title {
    	display: block !important;
    }
    .goto {
    	height: 1rem;
    	width: 1rem;
    }
    .listItem {
        border-bottom: 1px solid #b0b1b5;
        cursor: pointer !important;
        background: white;
        padding: 0.5rem;
    }
    .blurred {
	  -webkit-filter: blur(5px);
	  -moz-filter: blur(5px);
	  -o-filter: blur(5px);
	  -ms-filter: blur(5px);
	  filter: blur(5px);
    }
    .goto_details {
    	height: 0.8rem;
    	width: 0.8rem;
    }
    .description {
    	white-space: nowrap;
    	width: 25rem;
    	overflow: hidden;
    	text-overflow: ellipsis;
    }
    .enabled {
    	color: #345d34;
    	background: #6ce080;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    pre {
      background: #eceef1;
      padding: 1rem !important;
      border-radius: 5px;
    }
    .activeItem {
      background: #eceef1;
    }
    .reveal {
	    position: absolute;
	    left: 6rem;
	    top: 1.7rem;
    }
    .endpoint {
    	background: #e6e7e8;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    /deep/ .card__title {
        border-bottom: 1px solid #f5f7fa;
        padding: 1.5rem 2rem 0 2rem;
    }
    </style>