    <template>
    	<div class="webhooks">
    		<AppTopbar
    		title="Webhook Detail"
    		buttonText="New Order"
    		buttonIconClass="icon-add"
    		buttonLink="/new"
    		/>

    		<div class="container-fluid pt-70 pt-md-98">
    			<div class="card">
    				<div class="card__title title web_details_mobile">
						<span class="fs18 lh24 fw500">
							{{webhookDetail.endpoint}}
						</span> 

    					<div class="text-right">
    						<b-button variant="primary" v-if="webhookDetail.status == 1" @click="disableWebhook(webhookDetail.id)"><img class="goto mr-5" src="@/assets/svg/disable.svg"> Disable </b-button>
                            <b-button variant="primary" v-if="webhookDetail.status == 2" @click="enableWebhook(webhookDetail.id)"><img class="goto mr-5" src="@/assets/svg/enable.svg"> Enable </b-button>  
    						<b-button class="ml-20" @click="deleteWebhook(webhookDetail.id)" variant="primary"><img class="goto mr-5" src="@/assets/svg/delete.svg"> Delete </b-button> 
    					</div>
    				</div>
    				<div class="card__content overflow-visible">
    					<div class="row col-md-12">
    						<div class="col-md-8 row">
    							<!-- <div class="col-md-2">
    								Endpoint Type
    								<p class="pt-10">
    									<div class="endpoint">Account</div>
    								</p>
    							</div> -->
    							<div class="col-md-2">
    								Status
    								<p class="pt-10">
    									<div class="enabled" v-if="webhookDetail.status == 1">Enabled</div>
                                        <div class="disabled" v-else>Disabled</div>
    								</p>
    							</div>
    							<div class="col-md-2">
    								Mode
    								<p class="pt-10">Production</p>
    							</div>
    							<div class="col-md-2">
    								Version
    								<p class="pt-10">{{webhookDetail.version}}</p>
    							</div>
    						</div>
    						<div class="col-md-4"></div>
    					</div>
    				</div>
    			</div>

    		</div>

    		<div class="container-fluid pt-30 pt-md-30">
    			<div class="card">
    				<div class="card__title title">
						<span class="fs18 lh24 fw500">
							Webhook Details
						</span> 

    					<div class="text-right">
    						<b-button variant="primary" @click="editWebhook"><img class="goto mr-5" src="@/assets/svg/editIcon.svg"> Update Details </b-button> 
    					</div>
    				</div>
    				<div class="card__content overflow-visible">
    					<div class="col-md-10">
    						<div class="row pt-10">
    							<div class="col md-1">URL</div>
    							<div class="col-md-9">{{webhookDetail.endpoint}}</div>
    						</div>
    						<div class="row pt-10">
    							<div class="col md-1">Description</div>
    							<div class="col-md-9" v-if="webhookDetail.description">{{webhookDetail.description}}</div>
                                <div class="col-md-9" v-else>N/A</div>
    						</div>
    						<div class="row pt-10">
    							<div class="col md-1">Event Types</div>
    							<div class="col-md-9">
                                    <p v-for="item in webhookDetail.topics" :key="item.index">
                                        {{item}}
                                    </p>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>

    		</div>


    		<div class="container-fluid pt-30 pt-md-30">
    			<div class="card">
    				<div class="card__title title">
						<span class="fs18 lh24 fw500">
							Signing Secret
						</span> 

    					<div class="text-right">
    						<b-link target="_blank" href="https://faq.shyplite.com/5008-getting-started-with-webhooks" class="ml-0 fs14">
    							<img class="goto_details mb-2" 
    							src="@/assets/svg/details.svg">
    						Learn more about webhook signing</b-link>
    					</div>
    				</div>
    				<div class="card__content overflow-visible title row">
    					<div class="my-auto ml-10" :class="{ blurred : !revealFlag }">
    						<span>
    							{{webhookDetail.signingKey}}
    						</span>
    					</div>
    					<b-button class="reveal" v-if="!revealFlag" @click="togglereveal" variant="primary" size="xs">Click to Reveal</b-button>
    					<div class="text-right update-secret">
						  <b-button @click="updateSecret(webhookDetail.id)" variant="primary"> Update Secret </b-button>
    					</div>
    				</div>
    			</div>

    		</div>

            <div class="container-fluid pt-30 pt-md-30 pb-30 pb-md-70">
                <div class="row col">
                    <b-button @click="getLogs(webhookDetail.id)" variant="primary"> View Webhook Logs </b-button>
                </div>
            </div>

            
    		<EditWebhook :editItem="webhookDetail" ref="editWebhook" />
    	</div>
    </template>

    <script>
    import Multiselect from "vue-multiselect";
    import AppTopbar from "@/components/layout/AppTopbar.vue";
    import EditWebhook from "@/components/EditWebhook.vue";

    export default {
    	name: "Webhook_Detail",
    	title: "Shyplite - Webhook Detail",
    	data() {
    		return {
    			revealFlag:false,
                webhook_id: '',
                webhookDetail:{}
    		};
    	},
    	components: {
    		Multiselect,
    		AppTopbar,
    		EditWebhook
    	},
    	computed:{
    		windowWidth() {
    			return this.$store.state.windowWidth;
    		}
    	},
        created() {
            this.webhook_id = this.$route.params.id
            this.getWebhook()
        },
    	methods: {
            getLogs(id) {
                let url = `/webhook/logs/${id}`
                this.$router.push(url)
            },
            async updateSecret(id) {
                const obj = {updateSecret: true} 
                try {
                    const res = await this.axios.post(`/webhooks/${id}`,obj)
                    if (res.data.status) {
                      this.popToast("booked", "Success!", "Secret Key Updated Successfully");
                      this.webhookDetail.signingKey = res.data.body.signingKey
                    } else {
                      this.popToast("failed", "Failed!", res.data.body.message);
                    }
                  } catch(e) {
                    this.popToast("failed", "Failed!", "Server Error");
                  }
            },
            async deleteWebhook(id) {
                const obj = {status:0} 
                try {
                    const res = await this.axios.post(`/webhooks/${id}`,obj)
                    if (res.data.status) {
                      this.popToast("booked", "Success!", "Webhook Deleted Successfully");
                      setTimeout(() => {
                          this.$router.go(-1)
                        },1000)
                    } else {
                      this.popToast("failed", "Failed!", res.data.body.message);
                    }
                  } catch(e) {
                    this.popToast("failed", "Failed!", "Server Error");
                  }
            },
            async disableWebhook(id) {
                const obj = {status:2} 
                try {
                    const res = await this.axios.post(`/webhooks/${id}`,obj)
                    if (res.data.status) {
                      this.popToast("booked", "Success!", "Webhook Disabled Successfully");
                      setTimeout(() => {
                          this.$router.go()
                        },1000)
                    } else {
                      this.popToast("failed", "Failed!", res.data.body.message);
                    }
                  } catch(e) {
                    this.popToast("failed", "Failed!", "Server Error");
                  }
            },
            async enableWebhook(id) {
                const obj = {status:1}
                try {
                    const res = await this.axios.post(`/webhooks/${id}`,obj)
                    if (res.data.status) {
                      this.popToast("booked", "Success!", "Webhook Enabled Successfully");
                      setTimeout(() => {
                          this.$router.go()
                        },1000)
                    } else {
                      this.popToast("failed", "Failed!", res.data.body.message);
                    }
                  } catch(e) {
                    this.popToast("failed", "Failed!", "Server Error");
                  }
            },
    		editWebhook() {
    			this.$refs.editWebhook.isOpen = !this.$refs.editWebhook.isOpen;
    		},
    		togglereveal() {
    			this.revealFlag = !this.revealFlag
    		},
            async getWebhook() {
                let id = this.webhook_id
                try {
                    const res = await this.axios.get(`/webhook/${id}`)
                    if (res.data.status) {
                        this.webhookDetail = Object.assign({}, res.data.body)
                        this.webhookDetail.topics = JSON.parse(this.webhookDetail.topics).filter(i => i != null)
                    } else {
                        // statement
                    }
                } catch(e) {
                    // statements
                    console.log(e);
                }
            }
    	},
    };
    </script>

    <style scoped>
    /deep/ a:hover, a:focus, a:active {
    	color: #006eff
    }
    .title {
    	justify-content: space-between !important;
    }
    .goto {
    	height: 1rem;
    	width: 1rem;
    }
    .blurred {
	  -webkit-filter: blur(5px);
	  -moz-filter: blur(5px);
	  -o-filter: blur(5px);
	  -ms-filter: blur(5px);
	  filter: blur(5px);
    }
    .goto_details {
    	height: 0.8rem;
    	width: 0.8rem;
    }
    .description {
    	white-space: nowrap;
    	width: 25rem;
    	overflow: hidden;
    	text-overflow: ellipsis;
    }
    .enabled {
    	color: #345d34;
    	background: #6ce080;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    .disabled {
    	color: #d60909;
    	background: #f17272;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.3rem;
    }
    .reveal {
	    position: absolute;
	    left: 6rem;
	    top: 1.7rem;
    }
    .endpoint {
    	background: #e6e7e8;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    </style>