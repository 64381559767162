    <template>
    	<div class="webhooks">
    		<AppTopbar
    		title="Webhook Detail"
    		buttonText="New Order"
    		buttonIconClass="icon-add"
    		buttonLink="/new"
    		/>


    		<div class="container-fluid pt-70 pt-md-98">
    			<div class="card">
    				<div class="card__title title">
                        <div>
                            <span class="fs18 lh24 fw500">
                                Test Sandbox
                            </span> 
                        </div>
    				</div>
    				<div class="card__content overflow-visible">
    					<b-form class="ml-8" @submit.prevent="testSandbox">
                            <div class="pt-10">
                              <div class="col-md-6 p-0">
                                <b-form-group
                                  id="item-weight-field"
                                  label=""
                                  label-for="endpoint_desc"
                                >
                                  <b-form-input
                                    required
                                    v-model="form.url"
                                    id="endpoint_desc"
                                    placeholder="Sandbox URL"
                                  ></b-form-textarea>
                                </b-form-input>
                                 </b-form-group>
                              </div>
                            </div>
                            <div class=" pt-20 ">
                                <div class="col-md-6 d-flex p-0 justify-content-between">
                                    <div class="col-md-5 p-0">
                                        <b-form-group id="mode-field" label="" label-for="events">
                                          <multiselect
                                            id="events"
                                            :options="options"
                                            placeholder="Select Event"
                                            track-by="name"
                                            label="name"
                                            :show-labels="false"
                                            v-model="form.events"
                                            :allow-empty="false"
                                            :searchable="false"
                                          >
                                          </multiselect>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-5 p-0">
                                        <b-form-group id="mode-field" label="" label-for="events">
                                          <multiselect
                                            id="events"
                                            :options="versions"
                                            placeholder="Select Version"
                                            track-by="name"
                                            :searchable="false"
                                            label="name"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            v-model="form.version"
                                          >
                                          </multiselect>
                                        </b-form-group>
                                    </div>
                                </div>
                                
                            </div>  

                            <div class="d-flex mt-10">
                              <div class="col p-0 pt-8">
                                <b-button :disabled="!(form.version && form.events)" type="submit" variant="primary" size="lg">
                                  <b-spinner v-if="testSpin"></b-spinner>
                                  Test Webhook
                                </b-button>
                              </div>
                            </div> 

                            <div class="row pt-30 pt-md-30">
                                <div class="col">
                                    <span class="fs16 text-gray-600">
                                        <strong>NOTE</strong> : The signing key for sandbox testing is always sandbox
                                    </span>
                                </div>
                            
                            </div>         
                        </b-form>
                        <div class="d-flex pt-30 pt-md-30 col-md-12">
                            <div class="col-md-6 p-0">
                                <label> <strong>Request JSON</strong> </label>
                                <pre v-highlightjs="requestAPI"><code class="javascript"></code></pre>
                            </div>
                            <div class="col-md-6">
                                <label> <strong>Response JSON</strong> </label>
                                <pre v-highlightjs="responseAPI"><code class="javascript"></code></pre>
                            </div>
                        </div>
    				</div>
    			</div>

    		</div>

    		
    	</div>
    </template>

    <script>
    import Multiselect from "vue-multiselect";
    import AppTopbar from "@/components/layout/AppTopbar.vue";
    import VueHighlightJS from 'vue-highlightjs'
    import Vue from 'vue'
    Vue.use(VueHighlightJS)

    export default {
    	name: "Sandbox_Webhook",
    	title: "Shyplite - Sandbox Webhook",
    	data() {
    		return {
    			revealFlag:false,
                webhook_id: '',
                webhookDetail:{},
                form:{},
                responseFirst:null,
                responseSecond:null,
                requestAPI:'',
                responseAPI:'',
                testSpin:false,
                options: [
                    { name: "ORDER", $isDisabled: true },
                    { name: "order.created", id: "1" },
                    { name: "order.updated", id: "2", $isDisabled: true,description:"Work In Progress" },
                    { name: "order.cancelled", id: "3" },
                    { name: "order.booked", id: "5" },
                    { name: "TRACKING", $isDisabled: true },
                    { name: "tracking.pickedup", id: "6" },
                    { name: "tracking.returnOrReverse", id: "7" },
                    { name: "tracking.outfordelivery", id: "8" },
                    { name: "tracking.delivered", id: "9" }
                    // { name: "BILLING", $isDisabled: true },
                    // { name: "billing.estimatedamount", id: "10" },
                    // { name: "billing.estimatedamount", id: "11" },
                    // { name: "PAYMENT", $isDisabled: true },
                    // { name: "payment.completed", id: "12" },
                    // { name: "payment.error", id: "13" },
                    // { name: "payment.pending", id: "14" },
                    // { name: "payment.failed", id: "15" },
                    // { name: "SERVICEABILITY", $isDisabled: true },
                    // { name: "serviceability.updated", id: "16" },
                  ],
                  versions: [
                    { name: "Select Version", $isDisabled: true },
                    { name: "1.0.0", id: "1" }
                  ]
    		};
    	},
    	components: {
    		Multiselect,
    		AppTopbar    	},
    	computed:{
    		windowWidth() {
    			return this.$store.state.windowWidth;
    		}
    	},
        created() {
        },
    	methods: {
            async testSandbox() {
                this.testSpin = true
                const obj = Object.assign({}, this.form)
   
                  obj['topic'] = obj.events.name
                  obj['version'] = obj.version.name
                  delete obj.events
                  delete obj.versions

                  console.log(obj)

                  try {
                      obj.url = new URL(obj.url)
                      const res = await this.axios.post('/test/webhook',obj)
                      console.log(res)
                      if (res.data.status) {
                        this.testSpin = false
                        let abc = res.data.body.request
                        this.requestAPI = JSON.stringify(abc, undefined, 4)
                        let def = res.data.body.response
                        this.responseAPI = JSON.stringify(def, undefined, 4)
                      } else {
                        this.testSpin = false
                          this.popToast('failed',"Failed",res.data.body.message)
                      }
                  } catch(e) {
                      this.testSpin = false
                      if(e.message.includes('Failed to construct')) {
                          this.popToast("failed", "Failed!", "Incorrect Endpoint");
                        } else {
                          this.popToast('failed',"Failed","Server Error!! Please Try Again Later")
                        }
                  }
            }
    	},
    };
    </script>

    <style scoped>
    /deep/ a:hover, a:focus, a:active {
    	color: #006eff
    }
    .title {
    	justify-content: space-between !important;
    }
    .goto {
    	height: 1rem;
    	width: 1rem;
    }
    .blurred {
	  -webkit-filter: blur(5px);
	  -moz-filter: blur(5px);
	  -o-filter: blur(5px);
	  -ms-filter: blur(5px);
	  filter: blur(5px);
    }
    .goto_details {
    	height: 0.8rem;
    	width: 0.8rem;
    }
    .description {
    	white-space: nowrap;
    	width: 25rem;
    	overflow: hidden;
    	text-overflow: ellipsis;
    }
    .enabled {
    	color: #345d34;
    	background: #6ce080;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    pre {
      background: #eceef1;
      padding: 1rem !important;
      border-radius: 5px;
      min-height: 20rem !important;
      max-height: 20rem !important;
      scroll-behavior: auto;
    }
    /*.disabled {
    	color: #d60909;
    	background: #f17272;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.3rem;
    }*/
    .reveal {
	    position: absolute;
	    left: 6rem;
	    top: 1.7rem;
    }
    .endpoint {
    	background: #e6e7e8;
    	width: 4rem;
    	border-radius: 3px;
    	padding: 0rem 0.5rem 0rem 0.4rem;
    }
    </style>