<template>
  <b-table
    :items="items"
    :fields="fields"
    no-border-collapse
    responsive
    class="dark-header serviceability"
    id="serviceability-table"
  >
    <!-- A custom formatted data column cell for field 'Prepaid' -->
    <template v-slot:cell(prepaid)="data">
      <div class="ml-14">
        <template v-if="data.item.prepaid == true">
          <i class="icon-booked text-green fs12"></i>
        </template>
        <template v-else>
          <i class="icon-failed text-red fs10"></i>
        </template>
      </div>
    </template>

    <!-- A custom formatted data column cell for field 'COD' -->
    <template v-slot:cell(cod)="data">
      <div class="ml-6">
        <template v-if="data.item.cod == true">
          <i class="icon-booked text-green fs12"></i>
        </template>
        <template v-else>
          <i class="icon-failed text-red fs10"></i>
        </template>
      </div>
    </template>

    <!-- A custom formatted data column cell for field 'Reverse' -->
    <template v-slot:cell(reverse)="data">
      <div class="ml-14">
        <template v-if="data.item.reverse == true">
          <i class="icon-booked text-green fs12"></i>
        </template>
        <template v-else>
          <i class="icon-failed text-red fs10"></i>
        </template>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "PincodeTable",
  props: ['items'],
  data() {
    return {
      fields: [
        {
          key: "modeService",
          label: "Mode / Service",
          sortable: true,
          thClass: "wsnw",
          tdClass: "wsnw",
        },
        { key: "prepaid", sortable: true },
        { key: "cod", label: "COD", sortable: true },
        { key: "reverse", sortable: true },
      ],
    };
  },
};
</script>

<style></style>
