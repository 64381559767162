<template>
    <b-modal
        id="edit-webhook-modal"
        modal-class="add-sku-modal"
        size="lg"
        hide-footer
        v-model="isOpen"
    >
        <template v-slot:modal-header="{ close }">
            <span class="d-block mt-auto fs25 lh24 fw500"> Log Details </span>
            <b-button variant="rounded" @click="close()">
                <i class="icon-close-variant"></i>
            </b-button>
        </template>

        <div class="mw596 mx-auto">
            <div class="d-flex flex-column" v-for="item in respData" :key="item[0]">
                <span class="mb-8">
                    <strong>Status: </strong> {{item[1].statusCode}}
                </span>
                
                <span class="mb-8">
                    <strong>Time: </strong> {{item[1].timestamp | logdate}}, {{item[1].timestamp | hourtime}}
                </span>
                
                <span class="mb-8">
                    <strong>API Version: </strong> {{item[1].apiVersion}}
                </span>
                
                <span class="mb-8" v-if="item[1].importedID">
                    <strong>Order ID: </strong> {{item[1].importedID}}
                </span>
                
                <span class="mb-20">
                    <strong>IP Address: </strong> {{item[1].ip}}
                </span>

                <label><strong>Response Body</strong></label>
                <pre v-highlightjs><code class="javascript">{{item[1].body}}</code></pre>
            </div>
        </div> 
    </b-modal>
</template>

<script>
    export default {
        name: "WebhookLogDetails",
        props: {
            respData: Array
        },
        data() {
            return {
                isOpen: this.modalOpen,
            };
        },
        methods: {

        },
    };
</script>   
<style scoped>
    pre {
      background: #eceef1;
      padding: 1rem !important;
      border-radius: 5px;
    }
</style>      
