var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    staticClass: "dark-header serviceability",
    attrs: {
      items: _vm.items,
      fields: _vm.fields,
      "no-border-collapse": "",
      responsive: "",
      id: "serviceability-table"
    },
    scopedSlots: _vm._u([
      {
        key: "cell(prepaid)",
        fn: function(data) {
          return [
            _c(
              "div",
              { staticClass: "ml-14" },
              [
                data.item.prepaid == true
                  ? [_c("i", { staticClass: "icon-booked text-green fs12" })]
                  : [_c("i", { staticClass: "icon-failed text-red fs10" })]
              ],
              2
            )
          ]
        }
      },
      {
        key: "cell(cod)",
        fn: function(data) {
          return [
            _c(
              "div",
              { staticClass: "ml-6" },
              [
                data.item.cod == true
                  ? [_c("i", { staticClass: "icon-booked text-green fs12" })]
                  : [_c("i", { staticClass: "icon-failed text-red fs10" })]
              ],
              2
            )
          ]
        }
      },
      {
        key: "cell(reverse)",
        fn: function(data) {
          return [
            _c(
              "div",
              { staticClass: "ml-14" },
              [
                data.item.reverse == true
                  ? [_c("i", { staticClass: "icon-booked text-green fs12" })]
                  : [_c("i", { staticClass: "icon-failed text-red fs10" })]
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }